* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: "Sora", sans-serif;
}

body {
  font-size: 100%;
  color: #FFFFFF;
}

.gradient {
  background-image: linear-gradient(0deg, #08283E, rgb(40, 116, 252));
  padding-bottom: 20px;
}

/* HEADER COMPONENT*/

.navbar-logo {
  width: 80px;
}

.container-navbar-root {
  background-color: #010F16 !important;
}

.container-navbar {
  background-color: #010F16 !important;
}

.nav-link-text {
  color: white !important;
  font-size: larger !important;
}

.nav-link-text:visited {
  color: white;
}

.nav-link-text:hover {
  color: orange !important;
}

/* FOOTER COMPONENT*/

.footer-1 {
  text-align: center;
  padding: 30px;
  background-color: #011F2F;
  color: white;
  line-height: 1.5;
  padding-bottom: 10px !important;
}

.footer-2 {
  background-color: #010F16;
  color: white;
  padding: 20px;
  text-align: center;
}

/* LEADS PAGE*/

.leads {
  margin: 20px;
}

.clients-title {
  text-align: center;
  margin-top: 20px;
  color: #010F16;
}

.clients-user {
  text-align: center;
}

.leads-new {
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.leads-count-text {
  color: green;
  font-size: larger;
  font-weight: 700;
}

.lead-button {
  cursor: pointer;
}

.container-client {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lead-card {
  border-radius: 10px !important;
  padding: 20px;
  text-align: center;
  width: 90%;
  margin: 10px;
  line-height: 0.8;
  box-shadow: 0 4px 8px -1px rgba(0, 0, 0, .50);
  color: black;
}

.date {
  width: 100%;
  background-color: white;
  cursor: text;
  font-size: 10px;
  text-align: center;
  border: none;
}

.delete-button {
  padding: 16px;
  margin: 10px;
  background-color: darkred;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
  font-size: large;
}

.delete-button:hover {
  background-color: azure;
  color: darkred;
  border: 1px solid gray;
}

/* LOGIN PAGE*/

.login-form {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid lightblue;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 20px;
  color: black;
}

.login-input-label {
  font-weight: 700;
  margin-top: 16px;
}

.login-input {
  display: inline-block;
  padding: 10px;
  font-size: large;
  margin-top: 6px;
  width: 100%;
  border-radius: 6px;
  border: 1px solid lightgray;
}

.login-btn {
  padding: 10px;
  border-radius: 20px;
  font-size: large;
  border: 2px solid lightgray;
  background-color: #010F16;
  color: azure;
  cursor: pointer;
}

.login-btn:hover {
  background-color: azure;
  color: #010F16;
}

.container-show-password {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.login-checkbox {
  padding: 6px;
  transform: scale(1.5);
}

.login-checkbox-label {
  font-size: large;
  margin-left: 10px;
}

/* FORM COMPONENT*/

.form-header {
  text-align: center;
  color: black;
  margin: 20px;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  padding: 2px;
}

.form {
  margin: 20px;
  box-shadow: 0 4px 8px -1px rgba(0, 0, 0, .50);
  border-radius: 10px;
  padding: 20px;
  color: #010F16;
  background-color: rgb(255, 255, 255);
  opacity: 0.8;
}

.asterisco {
  color: red;
}

.text-input-label {
  font-weight: 700;
  margin-top: 16px;
  display: inline-block;
}

.form p {
  font-weight: 700;
  margin-top: 16px !important;
  margin-bottom: 10px;
}

.input-radio-label {
  margin-left: 10px;
}

.text-input {
  padding: 10px;
  font-size: large;
  margin-top: 6px;
  width: 100%;
  border-radius: 6px;
  border: 2px solid lightgray;
}

.text-input:focus {
  border: 2px solid rgb(66, 133, 244);
}

.form-textarea {
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  margin-top: 8px;
}

.submit-button {
  padding: 10px;
  font-size: large;
  width: 100%;
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid lightgray;
  cursor: pointer;
  background-color: #010F16;
  color: azure;
}

.submit-button:hover {
  background-color: azure;
  color: #010F16;
  border: 1px solid #010F16;
}

.form-privacy {
  text-align: center;
  font-size: small;
  margin-top: 10px;
}

::placeholder {
  font-size: 0.9rem;
}

/* HOME PAGE */

.open-image {
  background-image: url('/src//assets//images/placas.jpeg');
  background-size: cover;
  padding-bottom: 30px;
}

.home--title--1 {
  font-size: 1.7rem;
  text-align: center;
  padding: 20px;
  padding-top: 20px;
  font-weight: 700;
  color: black;
}

.home--title--1 span {
  font-size: 2rem;
  text-decoration: underline;
  color: black;
  position: relative;
  animation-name: slide-from-left;
  animation-duration: 1s;
}

.home--title--4 {
  text-align: center;
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 1.2rem;
  text-shadow: 2px 2px 8px black;
  padding: 10px;
  color: black;
}

.home--title--4--2 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 1.2rem;
  text-shadow: 2px 2px 8px black;
  color: black;
}

.objetivos--title-1 {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.objetivos--title-3 {
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
}

.objetivos--container--1 {
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  margin-right: 14px;
  margin-left: 14px;
  margin-bottom: 20px;
}

.objetivos--container--2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 2s;
}

.objetivos--container--imagem {
  width: 150px;
}

.objetivos--container--texto {
  color: #08283E;
  font-size: 1.7rem;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: 700;
  transition: all 0.3s;
  text-align: center;
}

.bonus--container {
  background-color: white;
  color: #08283E;
  padding: 10px;
  text-align: center;
}

.bonus--title--1 {
  font-size: 3rem;
  margin-top: 30px;
  margin-bottom: 20px;
}

.bonus--title--2 {
  font-weight: 400;
  font-size: 1.4rem;
}

.desconto--container {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 500px;
}

.desconto-item h2 {
  border-radius: 20px;
  background-color: #08283E;
  color: white;
  padding: 30px;
}

.duvidas-title {
  color: black;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.accordion-container {
  margin: 20px;
}

.accordion-body {
  text-align: justify;
}

.whatsapp-button {
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	border-radius:50px;
  padding: 4px;
  background-color: #FFF;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

/* CONTACT PAGE */

.contact-info {
  margin: 20px;
  padding: 10px;
  text-align: center;
  color: black;
}

/* THANK YOU PAGE */

.back-button {
  padding: 10px;
  font-size: large;
  width: 80%;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid lightgray;
  cursor: pointer;
  background-color: #011F2F;
  color: azure;
}

.back-button:hover {
  background-color: azure;
  color: #011F2F;
}

/* ANIMATIONS */
@keyframes slide-from-left {
  0% {
    left: -100%;
  }

  100% {
    left: 0%;
  }
}

/* NO ARROWS ON NUMBER INPUT */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* MEDIA QUERIES */

@media only screen and (min-width: 600px) {
  .home--video {
    height: 350px;
    max-width: 600px;
  }

  .objetivos--container--1 {
    margin-right: 25%;
    margin-left: 25%;
    padding: 70px;
  }

  .objetivos--container--2:hover {
    transform: scale(1.2);
  }

  .orcamento--container {
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media only screen and (min-width: 768px) {

  .form {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .login-form {
    width: 40%;
  }

  .form-header {
    text-align: center;
    color: black;
    margin: 20px;
    background-color: rgba(255, 255, 255, 0.0);
    padding: 2px;
  }

  .accordion-container {
    width: 60% !important;
    margin-left: auto;
    margin-right: auto;
  }

}

@media only screen and (min-width: 1024px) {
  .back-button {
    width: 50%;
  }
}